import store from '@v/Modules/MarketingMessage/Core/Site/store';
import MarketingMessage from '@v/Modules/MarketingMessage/Core/Site/MarketingMessage.vue';

if (document.querySelector('section.marketing-message')) {
    window['marketing_message_vue_instance'] = new Vue({
        store,
        el: 'section.marketing-message',
        render: (h) => h(MarketingMessage, {
            props: {
                template: "PowerwebTemplate1"
            }
        })
    })
}

// adds data-mm to the body if the marketing message is loaded on a page
document.body.setAttribute(`data-mm`, 'true');