import Vuex from 'vuex';

import marketingMessage from './modules/marketing-message';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        marketingMessage
    }
})