import { utilityMutations } from '@v/Core/Global/Store/utility'

export const state = {
    slides: [],
    template: "Template1"
}

export const mutations = {
    ...utilityMutations,
    SET_SLIDES(state, slides) {
        state.slides = slides;
    },
    SET_TEMPLATE(state, template) {
        state.template = template;
    },
}

export const actions = {
    fetchData({commit}) {
        return axios.get('/slides', { params: { url: window.location.pathname }})
            .then(res => {
                commit('SET_SLIDES', res.data.marketing_messages);
                commit('SET_TEMPLATE', res.data.template);
            }).catch(err => {
                console.error(err);
            })
    }
}

export default { namespaced: true, state, mutations, actions };