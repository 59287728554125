<template>
    <section class="marketing-message show-on-load relative overflow-hidden" aria-label="Marketing Message" v-if="slides && slides.length">
        <component
            :is="templateComponent"
        ></component>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: "MarketingMessage",
    computed: {
        ...mapState('marketingMessage', [
            "slides",
            "template"
        ]),
        templateName() {
            return this.template || 'Template1';
        },
        templateComponent() {
            let prefix = 'MarketingMessage',
                registeredComponents = Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this.$options).components)),
                check  = Object.keys(registeredComponents).includes(prefix + this.templateName);

            return prefix + ( check ? this.templateName : 'Template1');
        }
    },
    methods: {
        ...mapActions('marketingMessage', [
            "fetchData"
        ])
    },
    mounted() {
        this.fetchData()
    }
};
</script>